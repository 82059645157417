<template>
  <div class="living clearfix">
    <negotiateLive
      :isDateType="isType"
      :livingList="livingList"
      @dateChange="getAgendaList"
      @toLivePath="toLivePath"
      ref="livePage"
    ></negotiateLive>
    <videoPlay ref="videoPlay"></videoPlay>
  </div>
</template>
<script>
/**
 * 传给父组件的方法：
 * 1.dateChange(item)点击日历切换日期发射的方法（此方法主要功能就是刷新数据，把当前页面数据改成所选日期的数据）
 * 2.toLivePath(item)点击进入直播的方法
 * 3.isType:date/tabs 两种类型
 *
 * 插槽：slot：status（右边按钮）
 */
import negotiateLive from "~/baseComponents/negotiateLive";
import { secondFormat, lineFormat, formatDate } from "~/basePlugins/filters";
import { getAilaDomainName } from "~/baseUtils";
import videoPlay from "@/baseComponents/videoPlay";
export default {
  components: {
    negotiateLive,
    videoPlay,
  },
  props: {
    isType: {
      type: String,
      default: "date",
    },
  },
  data() {
    return {
      livingList: [],
    };
  },
  mounted() {
    this.getAgendaList();
  },
  methods: {
    // 获取当天直播
    async getAgendaList(data) {
      this.$refs.livePage.loadingShow(true);
      let todayStart = moment().startOf("day");
      let todayEnd = moment().endOf("day");
      let now_time = moment(new Date()).unix();
      let meetStart = this.MEETING_INFO.start_time; //1609862400
      let meetEnd = this.MEETING_INFO.end_time;
      let params = {
        meeting_id: this.MEETING_ID,
        user_id: this.USER_INFO.id,
        nopage: 1,
      };
      if (this.isType == "date" && now_time > meetStart && now_time < meetEnd) {
        params.start_time = moment(todayStart).unix();
        params.end_time = moment(todayEnd).unix();
      } else {
        params.start_time = meetStart;
        params.end_time = meetEnd;
      }
      let _params = Object.assign(params, data);
      let result = await this.$store.dispatch(
        "baseStore/getAgendaLiveList",
        _params
      );
      if (result.success) {
        this.$refs.livePage.loadingShow(false);
        result.data.forEach((it) => {
          it.now_time = now_time;
          if (it.start_time > now_time) {
            it.isTimer = 1;
          } else if (it.start_time <= now_time && now_time < it.end_time) {
            it.isTimer = 2;
          } else if (it.end_time < now_time) {
            it.isTimer = 3;
          } else {
            it.isTimer = "";
          }
        });
        let arr = result.data.filter((it) => {
          return it.agenda_type != 2;
        });
        this.timerList = arr;
        this.livingList = formatDate(arr);
      } else {
        this.$refs.livePage.loadingShow(false);
      }
    },
    toLivePath(item) {
      if(this.PJSource == 21) {
        if(!this.IS_LOGIN) {
          this.$router.push("/sign");
          return
        }
      }
      this.$log({
        type: 2,
        id: "live.channel",
        topic: "live",
        content: JSON.stringify({
          agenda_id: item.id,
        }),
      });
      let path = getAilaDomainName("zh");
      if (item.agenda_type == 2 && item.live_type == 2) {
        // if (item.start_time - 300 < item.now_time) {
        //   let params = _encode({
        //     channel_id: item.channel_id,
        //     user_id: this.USER_INFO.id,
        //     anchor_id: item.guests,
        //   });
        //    let herfPath = `${path}/networkTalking/biunique?parameter=${params}`;
        //   // let url = `/networkTalking/groupChat?initialParams=${this.USER_INFO.id}&parameter=${params}`;
        //   // this.openAiLaWebsite(url);
        // } else {
        //   this.$message.info("请提前10分钟进入");
        // }
      }
      if (item.playback_url && item.playback_url.indexOf("http") != -1) {
        window.open(item.playback_url);
        return;
      }
      if (item.playback_url) {
        if (this.IS_LOGIN) {
          this.$refs.videoPlay.startPlay(item.playback_url);
        } else {
          this.$router.push("/sign");
        }
        return;
      } else if (item.watch_url) {
        window.open(item.watch_url);
      } else {
      }
      
    },
  },
   
      
};
</script>