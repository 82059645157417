<template>
  <div class="allVideo">
    <div class="selectBtn">
      <div class="btns clearfix">
        <!-- <div
          class="fl"
          :class="{ active: radio === 2 }"
          @click="liveGroupChange(2)"
          v-if="meeting_status === 2"
        >
          {{$t('itslive')}}
        </div>
        <div
          class="fl"
          :class="{ active: radio === 1 }"
          @click="liveGroupChange(1)"
          v-if="meeting_status !== 3"
          v-if="meeting_status !== 1"
        >
          {{$t('upcoming')}}
        </div> -->
        <div
          class="fl"
          :class="{ active: radio === 3 }"
          @click="liveGroupChange(3)"
        >
          {{$t('reply')}}
        </div>
      </div>
    </div>
    <div class="content clearfix" v-loading="loading">
      <videoItem
        class="fl"
        :item="item"
        :inex="index"
        :radio="radio"
        v-for="(item, index) in videoList"
        :key="index"
      />
      <noDataImg v-if="videoList.length === 0"></noDataImg>
    </div>
    <div class="footer" v-if="total > 8">
      <el-button
        class="morebut"
        size="small"
        @click="getMore"
        :loading="moreLoading"
        v-if="!noMore"
        >{{ $t("loadMore") }}
      </el-button>
      <el-button class="morebut" size="small" v-else>
        {{ $t("noMore") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import videoItem from "~scr/components/preaching/videoItem";
export default {
  name: "allVideo",
  components: {
    videoItem,
  },
  data() {
    return {
      radio: 3,
      moreLoading: false,
      noMore: false,
      total: 0,
      videoList: [],
      loading: false,
      currentPage: 1,
      meeting_status: 1,
    };
  },
  watch: {},
  created() {
    // let meeting_status;
    // let info = this.MEETING_INFO;
    // let nowTime = moment(new Date()).unix();
    // if (nowTime < info.start_time) {
    //   // 未开始
    //   this.radio = 1;
    //   meeting_status = 1;
    // } else if (info.start_time < nowTime && info.end_time > nowTime) {
    //   // 进行中
    //   this.radio = 2;
    //   meeting_status = 2;
    // } else if (info.end_time < nowTime) {
    //   // 结束了
    //   this.radio = 3;
    //   meeting_status = 3;
    // } else {
    // }
    // this.meeting_status = meeting_status;
  },
  mounted() {
    this.getList();
  },
  methods: {
    liveGroupChange(e) {
      this.radio = e;
      this.currentPage = 1;
      this.noMore = false;
      this.getList();
    },
    async getMore() {
      this.loading = true;
      let timer = new Date();
      let now_time = moment(timer).unix();
      let params = {};
      params.meeting_id = this.MEETING_ID;
      params.user_id = this.USER_INFO.id;
      params.nopage = 0;
      params.start = this.currentPage;
      params.limit = 8;
      params.agenda_status = this.radio;
      params.start_time = now_time;
      try {
        let res = await this.$store.dispatch(
          "baseStore/getAgendaLiveList",
          params
        );
        if (res.success) {
          res.data.forEach((ee) => {
            ee.now_time = now_time;
          });
          this.videoList = this.videoList.concat(res.data);
          this.currentPage++;
          this.total = res.count;
          this.loading = false;
          if (res.data.length != 8) {
            this.noMore = true;
          }
        }
      } catch (e) {}
    },
    async getList() {
      this.loading = true;
      let timer = new Date();
      let now_time = moment(timer).unix();
      let params = {};
      params.meeting_id = this.MEETING_ID;
      params.user_id = this.USER_INFO.id;
      params.nopage = 0;
      params.start = this.currentPage - 1;
      params.limit = 8;
      params.agenda_status = this.radio;
      params.start_time = now_time;
      try {
        let res = await this.$store.dispatch(
          "baseStore/getAgendaLiveList",
          params
        );
        if (res.success) {
          res.data.forEach((ee) => {
            ee.now_time = now_time;
          });
          this.videoList = res.data;
          this.total = res.count;
        }
        this.loading = false;
      } catch (e) {}
    },
  },
};
</script>

<style scoped lang="less">
.allVideo {
  .selectBtn {
    margin-bottom: 25px;
    /deep/ .el-radio-button__inner {
      border: 0;
      padding-right: 38px;
      font-size: 18px;
      padding-left: 0;
      background: #f0f2f5;
      color: #999;
    }
    /deep/ .first {
      padding-left: 0;
    }
    .btns {
      font-size: 18px;
      div {
        margin-right: 38px;
        color: #999;
        cursor: pointer;
        &.active {
          color: #409eff;
        }
      }
    }
  }
  .content {
    min-height: 300px;
    position: relative;
  }

  .footer {
    padding-top: 10px;
    padding-bottom: 50px;
    text-align: center;
    .morebut {
      height: 32px;
      padding: 0 40px;
    }
  }
}
</style>
