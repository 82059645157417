<template>
  <div class="item">
    <div class="position">
      <el-image
        @click="handleClickDown(item, 1)"
        style="width: 300px; height: 169px"
        :class="{
          cursor:
            item.agenda_type == 1 || (item.agenda_type == 2 && radio != 3),
        }"
        :src="item.live_img ? item.live_img : defaultImg"
      ></el-image>
      <div class="styleIcon clearfix" v-if="item.start_time < item.now_time">
        <span
          class="fl"
          :class="
            item.start_time < item.now_time && item.end_time >= item.now_time
              ? 'liveing'
              : item.end_time < item.now_time
              ? 'endLive'
              : 'startLive'
          "
        ></span>
        <div class="fl">
          <p
            v-show="
              item.start_time < item.now_time && item.end_time >= item.now_time
            "
          >
            {{$t('meetingBegining')}}
          </p>
          <p v-show="item.end_time < item.now_time">{{$t('meetingReturnLive')}}</p>
        </div>
      </div>
    </div>
    <div class="content" v-if="item.guestsType === 2">
      <div
        @click="handleClickDown(item)"
        class="textOverflow"
        :title="item.issue_en | priorFormat(item.issue_zh, LOCALE)"
        :class="{
          cursor:
            item.agenda_type == 1 || (item.agenda_type == 2 && radio != 3),
        }"
      >
        <span v-show="item.agenda_type == 1"> {{$t('meetingZhibo')}}： </span>
        <span v-show="item.agenda_type == 2">圆桌会议：</span>
        <span
          :title="
            item.issue_en | priorFormat(item.issue_zh, LOCALE) | lineFormat
          "
          >{{
            item.issue_en | priorFormat(item.issue_zh, LOCALE) | lineFormat
          }}</span
        >
      </div>
      <div class="textOverflow companyName">
        <div
          class="guestItem"
          v-for="(ii, dd) in item.guests"
          :key="dd"
          v-if="dd == 0"
        >
          <span class="name">{{
            ii.name_en | priorFormat(ii.name_zh, LOCALE)
          }}</span>
          <span
            class="title"
            :title="ii.job_title_en | priorFormat(ii.job_title_zh, LOCALE)"
            >{{ ii.job_title_en | priorFormat(ii.job_title_zh, LOCALE) }}</span
          >
        </div>
        <div class="more" v-if="item.guests.length > 1">
          <el-popover placement="right" width="300" trigger="click">
            <div class="guestItem" v-for="(ii, dd) in item.guests" :key="dd">
              <span class="name">{{
                ii.name_en | priorFormat(ii.name_zh, LOCALE)
              }}</span>
              <span
                class="title"
                :title="ii.job_title_en | priorFormat(ii.job_title_zh, LOCALE)"
                >{{
                  ii.job_title_en | priorFormat(ii.job_title_zh, LOCALE)
                }}</span
              >
            </div>
            <span class="moreguests" slot="reference">···</span>
          </el-popover>
        </div>
      </div>
      <div class="clearfix">
        <div class="fl">
          {{ item.start_time | secondFormat("LLL") }} -
          {{ item.end_time | secondFormat("T") }}
        </div>
        <div class="fr" v-if="item.start_time > item.now_time">
          <!-- <span
              class="mainColor cursor"
              v-show="!item.is_subscribe"
              @click="remind(item, index)"
          >立即预约</span
          > -->
        </div>
      </div>
    </div>
    <div class="content" v-if="item.guestsType === 1">
      <div
        @click="handleClickDown(item)"
        class="textOverflow"
        :title="item.issue_en | priorFormat(item.issue_zh, LOCALE)"
        :class="{
          cursor:
            item.agenda_type == 1 || (item.agenda_type == 2 && radio != 3),
        }"
      >
        <span v-show="item.agenda_type == 1"> 直播： </span>
        <span v-show="item.agenda_type == 2">圆桌会议：</span>
        <span
          :title="
            item.issue_en | priorFormat(item.issue_zh, LOCALE) | lineFormat
          "
          >{{
            item.issue_en | priorFormat(item.issue_zh, LOCALE) | lineFormat
          }}</span
        >
      </div>
      <div class="textOverflow companyName">
        <div
          class="guestItem"
        >
          <span class="name">{{
            item.guests | textFormat
          }}</span>
        </div>
      </div>
      <div class="clearfix">
        <div class="fl">
          {{ item.start_time | secondFormat("LLL") }} -
          {{ item.end_time | secondFormat("T") }}
        </div>
        <div class="fr" v-if="item.start_time > item.now_time">
          <!-- <span
              class="mainColor cursor"
              v-show="!item.is_subscribe"
              @click="remind(item, index)"
          >立即预约</span
          > -->
        </div>
      </div>
    </div>
    <videoPlay ref="videoPlay"></videoPlay>
  </div>
</template>

<script>
import videoPlay from "@/baseComponents/videoPlay";

export default {
  name: "videoItem",
  components: {
    videoPlay,
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    index: {
      default: 0,
    },
    radio: {
      default: 0,
    },
  },
  data() {
    return {
      defaultImg: `https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/boothDefaultCoverZh.jpg`,
    };
  },
  methods: {
    handleClickDown(item, type) {
      if(this.PJSource == 21) {
        if(!this.IS_LOGIN) {
          this.$router.push("/sign");
          return
        }
      }
      let time = moment(new Date()).unix();
      if (item.agenda_type == 2) {
        if (item.end_time < time) {
        } else {
          if (item.start_time - 600 < item.now_time) {
            let params = _encode({
              channel_id: item.channel_id,
              user_id: this.USER_INFO.id,
              anchor_id: item.guests,
            });
            let url = `/networkTalking/groupChat?initialParams=${this.USER_INFO.id}&parameter=${params}`;
            this.openAiLaWebsite(url);
          } else {
            this.$message.info("请提前10分钟进入");
          }
        }
      } else if (item.agenda_type == 1) {
        if (item.end_time < time) {
          //直播已结束
          if (item.playback_url && item.playback_url.indexOf("http") != -1) {
            window.open(item.playback_url);
            return;
          }
          if (item.playback_url) {
            if (this.IS_LOGIN) {
              this.$refs.videoPlay.startPlay(item.playback_url);
            } else {
              this.$router.push("/sign");
            }
            return;
          }
          if (item.watch_url) {
            window.open(item.watch_url);
            return;
          }
        } else {
          window.open(item.watch_url);
        }
      }
    },
    // 点击预约或取消预约
    remind(it, index, type, order) {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      }
      if (order == 2) {
        this.delRemind(it, index, type);
      } else {
        this.getRemindData(it, index, type);
      }
    },
    async getRemindData(it, index, type) {
      let data = await this.$store.dispatch(
        "API_booth/reservationMeetingBoothAgenda",
        {
          agenda_type: it.live_type,
          user_id: this.USER_INFO.id,
          meeting_id: this.MEETING_ID,
          remind_time: it.start_time,
          agenda_id: it.id,
        }
      );
      if (data.success) {
        if (type) {
          this.item.is_subscribe = 1;
        } else {
          this.item.is_subscribe = 1;
        }
      }
    },
    // 取消预约直播
    async delRemind(item, index, type) {
      let res = await this.$store.dispatch("API_booth/deleteLiveOrder", {
        appointment_remind_id: item.remind_id,
      });
      if (res.success) {
        if (type) {
          this.item.is_subscribe = 0;
        } else {
          this.item.is_subscribe = 0;
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.guestItem {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .name {
    font-size: 12px;
  }
  .title {
    color: #999;
    padding-left: 5px;
  }
}
.item {
  font-size: 12px;
  margin-left: 33px;
  margin-bottom: 24px;

  &:nth-child(4n + 1) {
    margin-left: 0;
  }

  .content {
    width: 300px;
    background: rgba(255, 255, 255, 1);
    // border: 1px solid rgba(233, 233, 233, 1);
    padding: 10px 12px 15px;
    position: relative;
    top: -6px;

    .companyName {
      display: flex;
      align-items: center;
      height: 25px;
      line-height: 25px;
      margin-bottom: 5px;
      .more {
        font-weight: 900;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .time {
      font-size: 12px;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      margin-bottom: 15px;
    }
  }
}

.cursor {
  cursor: pointer;
}

.styleIcon {
  top: 10px;
  position: absolute;
  right: 10px;
  padding: 2px 10px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  span {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fff;
    margin: 5px 5px 0 0;
  }

  .liveing {
    background: red;
  }

  .endLive {
    background: orange;
  }

  div,
  p {
    color: #fff;
  }
}

.dateIcon {
  position: absolute;
  top: 15px;
  right: 20px;

  .img {
    width: 25px;
    height: 25px;
  }
}

.size {
  font-size: 18px;
}

.position {
  position: relative;
}

.footer {
  padding-top: 10px;
  padding-bottom: 50px;
  text-align: center;

  .morebut {
    height: 32px;
    padding: 0 40px;
  }
}

.issueHover {
  &:hover {
    color: #26b7ff;
    cursor: pointer;
  }
}

.selectTime {
  /deep/ .el-dialog__body {
    padding: 0;
    width: 320px;
  }
}

/deep/ .el-picker-panel {
  margin: 0;
}
.moreguests{
  font-size: 18px;
  font-weight:500;
}
</style>
