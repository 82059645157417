<template>
  <div class="negotiates">
    <div class="content_area">
      <negotiate :isType="'tabs'"></negotiate>
      <allVideo></allVideo>
    </div>
  </div>
</template>
<script>
import negotiate from "~/baseComponents/negotiate";
import allVideo from "~exp/components/negotiate/allVideo";
export default {
  components: {
    negotiate,
    allVideo,
  },
  moment(){
     this.$log({
          type:1,
          id:'live',
          topic:'live',
         
        })
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.negotiates {
  background: #f0f2f5;
  padding: 30px 0;
}
</style>